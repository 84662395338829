import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><br /><i>And George settled down to dream<br />
Of a birthday celebration filled with <br />
Gifts, friends, cake, and ice cream!</i>
    <p>{`Join us as we celebrate Juan Marcus’ 1st birthday by singing a happy birthday song from the comforts of your own homes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      