import React from "react"
import AboutMDX from "../sections/about"
import { Grid, Box, Image } from "theme-ui";
import DividerImg from '../images/maui-divider.jpg'
import {
  dividerSection,
  divider,
  dividerFlip
} from './layout.module.css'
const Divider = ({className}) => (
    <Grid
        pl={0}
        pr={0}
        columns={[ 1 ]}
        className={className ? dividerFlip : ""}
        
    >
            <Image src={DividerImg}
                   className={divider}
            />
    </Grid>
)

export default Divider;