import React from "react"
import RegistryMDX from "../sections/registry"
import { Grid, Box, Image } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image"
import GiftRegistryImage from '../images/giftregistry.svg'
import MyRegistry from '../images/myregistry.png'
import {
  sectionRegistry,
  giftRegistry,
  registryButton,
  registryImageBox,
  yellowBox
} from './layout.module.css'
const Registry = () => (
    <Grid
        pt={100}
        pb={100}
        pl={4}
        pr={4}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="registry"
        className={sectionRegistry}
    >
        <div className="yellow-bg"></div>
        <style type="text/css">
        {`
        #registry {
            position: relative;
            margin-top: 8vh;
        }
        .yellow-bg {
            position: absolute;
            width: 120vw;
            background: #f3ed70;
            height: 110%;
            z-index: -1;
            left: -10vw;
            top: 0;
        }
        @media (orientation: landscape) {
            .yellow-bg {
                width: 200vw;
                left: -50vw;
            }
        }
      `}
      </style>
        <Box className={giftRegistry}>
            <Image src={GiftRegistryImage} />
            <RegistryMDX/>
            <a href="https://thenestaph.com/pages/swym-registry-gifter?registryId=2000640" target="_blank">
              <b>GIFT REGISTRY</b>
            </a>
        </Box>
        <Box sx={{
            textAlign: 'center'
        }} className={registryImageBox}>
            <StaticImage src='../images/maui-pic-3.jpg' alt="registry"/>
        </Box>
    </Grid>
)

export default Registry;