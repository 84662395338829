import React from "react";
import ContactMDX from "../sections/contact"
import {Box, Grid, Image} from "theme-ui";
import { StaticImage } from "gatsby-plugin-image"
import ContactUs from "../images/maui-zoom-bg-v2.jpg";
import ZoomParty from '../images/zoomparty.svg'
import {
  section,
  zoomTitle,
  flexOrdered,
  zoomimgContainer,
  zoomImageBox
} from './layout.module.css'
const Contact = () => (
    <Grid
        pt={100}
        pb={100}
        pl={0}
        pr={0}
        columns={[ 1, 2 ]}
        id="zoom"
        className={`${section} ${flexOrdered}`}
    >
        <Box sx={{
            textAlign: 'center'
        }} className={zoomimgContainer}>
            <a href={ContactUs} target="_blank" className={zoomImageBox}>
              <StaticImage src='../images/maui-zoom-bg-v2.jpg' alt="maui zoom"/>
            </a>
        </Box>
        <Box className={zoomTitle}>
            <Image src={ZoomParty} />
            <ContactMDX/>
        </Box>
    </Grid>
)

export default Contact;