import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`JUAN MARCUS' 1`}<sup>{`st`}</sup>{` BIRTHDAY`}<br />{`
2 SEPTEMBER 2024 at 11:00 AM`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      