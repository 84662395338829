// extracted by mini-css-extract-plugin
export var bannerTitle = "layout-module--banner-title--1Nc1Z";
export var mainBanner = "layout-module--main-banner--WlVfw";
export var partyImageBox = "layout-module--party-image-box--1S5ju";
export var zoomImageBox = "layout-module--zoom-image-box--2fGW5";
export var registryImageBox = "layout-module--registry-image-box--3bSTp";
export var demo = "layout-module--demo--2Vxi5";
export var coverImage = "layout-module--cover-image--3bTb7";
export var left = "layout-module--left--2E2A-";
export var right = "layout-module--right--20TEm";
export var navLinks = "layout-module--nav-links--2x6jG";
export var section = "layout-module--section--RVY4h";
export var sectionRegistry = "layout-module--section-registry--20u2m";
export var contact = "layout-module--contact--3eGIk";
export var balloonImage = "layout-module--balloon-image--hliFU";
export var volcanoBackground = "layout-module--volcano-background--2gV8s";
export var footer = "layout-module--footer--39oQl";
export var countdown = "layout-module--countdown--2NFpe";
export var divider = "layout-module--divider--1jiKP";
export var dividerSection = "layout-module--dividerSection--ijsYD";
export var dividerFlip = "layout-module--divider-flip--13Q4-";
export var zoomTitle = "layout-module--zoom-title--19y8Y";
export var giftRegistry = "layout-module--gift-registry--CsfxH";
export var registryButton = "layout-module--registry-button--2H1p_";
export var flexOrdered = "layout-module--flex-ordered--A7Aom";
export var zoomimgContainer = "layout-module--zoomimg-container--20Xij";
export var mainTitle = "layout-module--main-title--1qWEA";
export var registry = "layout-module--registry--1sOxZ";
export var yellowBg = "layout-module--yellow-bg--3mIAp";