import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><a href="https://adb-org.zoom.us/j/9656745357?pwd=Xw8bYwQRS7ltoMCOlua4Pbwaud2iPq.1&omn=98007673643" target="_blank">Join Zoom Meeting</a><br />
Passcode: marcus@1
    <p>{`Don't forget to save the wallpaper as your Zoom background. You may click the photo to open in a new tab.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      